import React from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { useTranslation } from 'react-i18next';
import { makeStyles } from '@material-ui/core/styles';

import Grid from '@material-ui/core/Grid';
import Paper from '@material-ui/core/Paper';
import Typography from '@material-ui/core/Typography';
import TextField from '@material-ui/core/TextField';
import Checkbox from '@material-ui/core/Checkbox';
import Select from '@material-ui/core/Select';
import FormControl from '@material-ui/core/FormControl';
import FormGroup from '@material-ui/core/FormGroup';
import FormControlLabel from '@material-ui/core/FormControlLabel';
import ListItemText from '@material-ui/core/ListItemText';
import InputLabel from '@material-ui/core/InputLabel';
import FormHelperText from '@material-ui/core/FormHelperText';
import Collapse from '@material-ui/core/Collapse';
import OutlinedInput from '@material-ui/core/OutlinedInput';
import Button from '@material-ui/core/Button';
import MenuItem from '@material-ui/core/MenuItem';
import Autocomplete from '@material-ui/lab/Autocomplete';
import { Chip, IconButton } from '@material-ui/core';
import CloseIcon from '@material-ui/icons/Close';
import parse from 'autosuggest-highlight/parse';
import automatch from 'autosuggest-highlight/match';

import HoldingActions from '../../../Redux/HoldingRedux';
import { CheckRoleRule } from '../../../Acl/Rules';
import Roles, { getFormattedRole, getRolesForOrganisation } from '../../../Acl/Roles';
import { canEditHolding, getRoleInOrga } from '../../../Acl/Controls';
import { getExpertiseStyle } from '../../../Themes/Expertises';
import InputMultiHours, {
  DEFAULT_HOUR_END,
  DEFAULT_HOUR_START,
} from '../../../Components/Posts/InputMultiHours';
import Editor from '../../../Components/Posts/EditorWysiwyg';

import { FilePond, registerPlugin } from 'react-filepond';
import FilePondPluginImageExifOrientation from 'filepond-plugin-image-exif-orientation';
import FilePondPluginImagePreview from 'filepond-plugin-image-preview';

registerPlugin(FilePondPluginImagePreview);
registerPlugin(FilePondPluginImageExifOrientation);

const useStyles = makeStyles(theme => ({
  appBarContainer: {
    flexGrow: 1,
    marginBottom: theme.spacing(1),
  },
  grow: {
    flexGrow: 1,
  },
  paper: {
    padding: theme.spacing(4),
    width: '100%',
  },
  selectLabel: {
    paddingLeft: theme.spacing(1),
    paddingRight: theme.spacing(1),
    backgroundColor: '#fff',
  },
  buttonContainer: {
    padding: theme.spacing(2),
    textAlign: 'center',
  },
  orgas: {
    paddingTop: theme.spacing(2),
    paddingBottom: theme.spacing(2),
  },
  orga: {
    paddingLeft: theme.spacing(2),
  },
  serviceSelected: {
    display: 'flex',
    alignItems: 'center',
    paddingTop: theme.spacing(3),
  },
  hoursLabel: {
    padding: theme.spacing(2, 0),
  },
  hours: {
    paddingLeft: theme.spacing(3),
    borderLeft: 'outset',
  },
  selected: {
    alignItems: 'center',
    padding: theme.spacing(1.5),
    borderRadius: '5px',
    border: `solid 1px ${theme.palette.grey[400]}`,
  },
  crossIcon: {
    flexGrow: 1,
    textAlign: 'end',
  },
  chip: {
    '&>*': {
      '&>*': {
        fill: 'inherit',
      },
    },
  },
}));

type Props = {
  organisation: Object,
  service: Object,
  onClose: Function,
  levelId: Number,
};

const ITEM_HEIGHT = 48;
const ITEM_PADDING_TOP = 8;
const MenuProps = {
  PaperProps: {
    style: {
      maxHeight: ITEM_HEIGHT * 4.5 + ITEM_PADDING_TOP,
      width: 250,
    },
  },
};

const ServiceForm = ({ service, organisation, onClose, levelId }: Props) => {
  const classes = useStyles();
  const dispatch = useDispatch();
  const { t } = useTranslation();
  const currentUser = useSelector(state => state.auth.currentUser);
  const expertises = useSelector(state => state.holding.allExpertises);
  const rubricExpertises = expertises?.filter(
    expertise => expertise.level?.id === levelId && expertise.expertise_type === 'service',
  );
  const [expertise, setExpertise] = React.useState(service?.expertise || null);
  const [label, setLabel] = React.useState(service?.label || '');
  const [description, setDescription] = React.useState(service?.description || '');
  const [images, setImages] = React.useState([]);
  const [distanceRate, setDistanceRate] = React.useState(service?.distance_rate || null);
  const [priceDescription, setPriceDescription] = React.useState(service?.price_description || '');
  const [adaptedCar, setAdaptedCar] = React.useState(service?.adapted_car || false);
  const [editable, setEditable] = React.useState(service?.editable || false);
  const [publicService, setPublic] = React.useState(service?.allow_public || false);
  const [escortRate, setEscortRate] = React.useState(service?.escort_rate || null);
  const [maxSeat, setMaxSeat] = React.useState(service?.max_seat || null);
  const [fixFee, setFixFee] = React.useState(service?.fix_fee || null);
  const [performedBy, setPerformedBy] = React.useState(service?.performed_by || 'professional');
  const [notifiableRoles, setNotifiableRoles] = React.useState(
    service?.notifiable_roles ||
      getRolesForOrganisation(organisation, t, [Roles.VOLUNTEER, Roles.PRIMARY]).map(
        role => role?.value,
      ),
  );

  const [openedAt, setOpenedAt] = React.useState(
    service?.opened_at ||
      JSON.stringify([
        [{ start: DEFAULT_HOUR_START, end: DEFAULT_HOUR_END }],
        [{ start: DEFAULT_HOUR_START, end: DEFAULT_HOUR_END }],
        [{ start: DEFAULT_HOUR_START, end: DEFAULT_HOUR_END }],
        [{ start: DEFAULT_HOUR_START, end: DEFAULT_HOUR_END }],
        [{ start: DEFAULT_HOUR_START, end: DEFAULT_HOUR_END }],
        [{ start: DEFAULT_HOUR_START, end: DEFAULT_HOUR_END }],
        [{ start: DEFAULT_HOUR_START, end: DEFAULT_HOUR_END }],
      ]),
  );

  const canEditService = canEditHolding(organisation, currentUser);
  const isTransport =
    service?.service_category?.slug === 'transport' ||
    expertise?.service_category?.slug === 'transport';
  const roles = getRolesForOrganisation(organisation, t, [Roles.ADMIN, Roles.PRIMARY]);
  const canSubmit = label?.trim() && description.length > 11 && expertise;

  React.useEffect(() => {
    if (performedBy === 'volunteer' && !notifiableRoles.includes(Roles.VOLUNTEER)) {
      setNotifiableRoles([...notifiableRoles, Roles.VOLUNTEER]);
    } else if (performedBy === 'professional') {
      setNotifiableRoles(notifiableRoles.filter(role => role !== Roles.VOLUNTEER));
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [performedBy]);

  React.useEffect(() => {
    if (service) {
      const cover = service.cover_key_basic_dpi;
      if (cover) {
        // current image preview
        setImages([
          {
            source: cover,
            options: {
              type: 'local',
              file: { name: cover, size: 0 },
              metadata: { poster: cover },
            },
          },
        ]);
      }
    }
  }, [service, setImages]);

  const handleChangeExpertise = value => {
    setExpertise(value);
    setLabel(value?.label);
    setDescription(value?.description);
  };

  const handleSubmit = event => {
    event.preventDefault();
    if (canEditService) {
      const serviceData = {
        label,
        description,
        service_category_id: service?.service_category?.id || expertise?.service_category?.id,
        distance_rate: distanceRate,
        price_description: priceDescription,
        adapted_car: adaptedCar,
        editable,
        allow_public: publicService,
        fix_fee: fixFee,
        escort_rate: escortRate,
        max_seat: maxSeat,
        notifiable_roles: notifiableRoles,
        performed_by: performedBy,
        level_id: service?.level?.id || levelId,
        slug: service?.slug,
        opened_at: openedAt,
        expertise_id: expertise?.id,
      };
      if (service?.id) {
        serviceData.id = service.id;
        dispatch(
          HoldingActions.updateHoldingServiceRequest(
            organisation.holding_slug,
            serviceData,
            images[0],
          ),
        );
      } else {
        dispatch(
          HoldingActions.addHoldingServiceRequest(
            organisation.holding_slug,
            serviceData,
            images[0],
          ),
        );
      }
    }

    onClose();
  };

  const renderTransport = () => (
    <>
      {/* price description */}
      <FormControl margin="normal" component="fieldset" className={classes.formControl} fullWidth>
        <TextField
          id="priceDescription"
          name="priceDescription"
          value={priceDescription}
          type="text"
          placeholder={t('APP.SERVICE.TEXT_FIELD.PLACEHOLDER.TRANSPORT.PRICE_DESCRIPTION')}
          onChange={event => setPriceDescription(event.target.value)}
          label={t('APP.SERVICE.TEXT_FIELD.LABEL.PRICE_DESCRIPTION')}
          variant="outlined"
          fullWidth
          multiline
          disabled={!canEditService}
          InputLabelProps={{
            shrink: true,
          }}
        />
      </FormControl>
      {/* distance rate */}
      <FormControl margin="normal" component="fieldset" className={classes.formControl} fullWidth>
        <TextField
          required
          id="distanceRate"
          name="distanceRate"
          value={distanceRate}
          type="number"
          onChange={event => setDistanceRate(event.target.value)}
          label={t('APP.SERVICE.TEXT_FIELD.LABEL.DISTANCE_RATE')}
          variant="outlined"
          fullWidth
          inputProps={{ min: 0, step: 0.01 }}
          defaultValue={0}
          disabled={!canEditService}
        />
      </FormControl>
      {/* flat fee */}
      <FormControl margin="normal" component="fieldset" className={classes.formControl} fullWidth>
        <TextField
          id="fixFee"
          name="fixFee"
          value={fixFee}
          type="number"
          onChange={event => setFixFee(event.target.value)}
          label={t('APP.SERVICE.TEXT_FIELD.LABEL.FIX_FEE')}
          variant="outlined"
          fullWidth
          inputProps={{ min: 0, step: 0.01 }}
          defaultValue={0}
          disabled={!canEditService}
        />
      </FormControl>
      {/* escort rate */}
      <FormControl margin="normal" component="fieldset" className={classes.formControl} fullWidth>
        <TextField
          id="escortRate"
          name="escortRate"
          value={escortRate}
          type="number"
          onChange={event => setEscortRate(event.target.value)}
          label={t('APP.SERVICE.TEXT_FIELD.LABEL.ESCORT_RATE_TRANSPORT')}
          variant="outlined"
          fullWidth
          inputProps={{ min: 0, step: 0.01 }}
          defaultValue={0}
          disabled={!canEditService}
        />
      </FormControl>
      {/* adapted car */}
      <FormControl margin="normal" component="div" fullWidth>
        <FormControlLabel
          component="div"
          label={t('APP.SERVICE.TEXT_FIELD.LABEL.ADAPTED_CAR')}
          control={
            <Checkbox
              name="adaptedCar"
              onChange={event => setAdaptedCar(event.target.checked)}
              checked={adaptedCar}
              color="primary"
              disabled={!canEditService}
            />
          }
        />
      </FormControl>
      {/* max seat */}
      <FormControl margin="normal" component="div" fullWidth>
        <TextField
          id="maxSeat"
          name="maxSeat"
          value={maxSeat}
          type="number"
          onChange={event => setMaxSeat(event.target.value)}
          label={t('APP.SERVICE.TEXT_FIELD.LABEL.MAX_SEAT')}
          variant="outlined"
          fullWidth
          inputProps={{ min: 0 }}
          defaultValue={0}
          disabled={!canEditService}
        />
      </FormControl>
      {/* editable */}
      <FormControl margin="normal" component="div" fullWidth>
        <FormControlLabel
          component="div"
          label={t('APP.SERVICE.TEXT_FIELD.LABEL.EDITABLE')}
          control={
            <Checkbox
              name="editable"
              onChange={event => setEditable(event.target.checked)}
              checked={editable}
              color="primary"
              disabled={!canEditService}
            />
          }
        />
      </FormControl>
    </>
  );

  const renderPrice = () => (
    <>
      {/* price description */}
      <FormControl margin="normal" component="fieldset" className={classes.formControl} fullWidth>
        <TextField
          id="priceDescription"
          name="priceDescription"
          value={priceDescription}
          type="text"
          placeholder={t('APP.SERVICE.TEXT_FIELD.PLACEHOLDER.PRICE_DESCRIPTION')}
          onChange={event => setPriceDescription(event.target.value)}
          label={t('APP.SERVICE.TEXT_FIELD.LABEL.PRICE_DESCRIPTION')}
          variant="outlined"
          fullWidth
          multiline
          disabled={!canEditService}
          InputLabelProps={{
            shrink: true,
          }}
        />
      </FormControl>
      {/* escort rate */}
      <FormControl margin="normal" component="fieldset" className={classes.formControl} fullWidth>
        <TextField
          required
          id="escortRate"
          name="escortRate"
          value={escortRate}
          type="number"
          onChange={event => setEscortRate(event.target.value)}
          label={t('APP.SERVICE.TEXT_FIELD.LABEL.ESCORT_RATE')}
          variant="outlined"
          fullWidth
          inputProps={{ min: 0, step: 0.01 }}
          defaultValue={0}
          disabled={!canEditService}
        />
      </FormControl>
    </>
  );

  return (
    <>
      <Grid container>
        <Paper className={classes.paper}>
          <div className={classes.instructions}>
            <form onSubmit={handleSubmit}>
              <Typography className={classes.grow} variant="h5" gutterBottom>
                {t(service ? 'APP.SERVICE.UPDATE' : 'APP.SERVICE.CREATE')}
              </Typography>

              {/* type */}
              {expertise && (
                <FormControl component="fieldset" fullWidth margin="normal">
                  {!service?.id && (
                    <InputLabel variant="outlined" shrink className={classes.selectLabel}>
                      {t('APP.SERVICE.SELECT_FIELD.LABEL.TYPE')}
                    </InputLabel>
                  )}
                  <Grid container flexdirection="row" className={!service?.id && classes.selected}>
                    <Grid item className={classes.chip}>
                      <Chip
                        variant="outlined"
                        label={t(getExpertiseStyle(expertise).label)}
                        icon={getExpertiseStyle(expertise).icon}
                        style={{ fill: getExpertiseStyle(expertise).color }}
                      />
                    </Grid>
                    {!service?.id && (
                      <Grid item className={classes.crossIcon}>
                        <IconButton size="small" onClick={() => setExpertise(null)}>
                          <CloseIcon fontSize="small" />
                        </IconButton>
                      </Grid>
                    )}
                  </Grid>
                </FormControl>
              )}
              {!expertise && (
                <FormControl margin="normal" variant="outlined" component="fieldset" fullWidth>
                  <Autocomplete
                    fullWidth
                    id="expertise-select"
                    options={rubricExpertises}
                    autoHighlight
                    defaultValue={{
                      label: t(getExpertiseStyle(expertise).label),
                      slug: expertise?.slug,
                    }}
                    getOptionLabel={exp => t(getExpertiseStyle(exp).label)}
                    renderOption={(exp, { inputValue }) => {
                      const matches = automatch(t(getExpertiseStyle(exp).label), inputValue);
                      const parts = parse(t(getExpertiseStyle(exp).label), matches);
                      return (
                        <Grid item className={classes.chip}>
                          <Chip
                            variant="outlined"
                            label={parts.map((part, index) => (
                              // eslint-disable-next-line react/no-array-index-key
                              <span key={`${exp.id}-${index}`}>{part.text}</span>
                            ))}
                            icon={getExpertiseStyle(exp).icon}
                            style={{ fill: getExpertiseStyle(exp).color }}
                          />
                        </Grid>
                      );
                    }}
                    renderInput={params => (
                      <TextField
                        {...params}
                        label={t('APP.SERVICE.SELECT_FIELD.LABEL.TYPE')}
                        variant="outlined"
                        InputLabelProps={{
                          shrink: true,
                        }}
                        required
                      />
                    )}
                    onChange={(event, value) => handleChangeExpertise(value)}
                    disabled={service?.id}
                  />
                </FormControl>
              )}

              {/* performed by */}
              <FormControl component="fieldset" fullWidth>
                <FormGroup aria-label="position" row style={{ alignItems: 'center' }}>
                  <Typography variant="h6">
                    {t('APP.SERVICE.SELECT_FIELD.LABEL.PERFORMED_BY')}
                  </Typography>
                  <FormControlLabel
                    value="volunteer"
                    control={
                      <Checkbox
                        color="primary"
                        disabled={!canEditService}
                        checked={performedBy === 'volunteer'}
                        onClick={() => setPerformedBy('volunteer')}
                      />
                    }
                    label={t('APP.SERVICE.SELECT_FIELD.LABEL.PERFORMED_BY.VOLUNTEER')}
                    labelPlacement="start"
                  />
                  <FormControlLabel
                    value="professional"
                    control={
                      <Checkbox
                        color="primary"
                        disabled={!canEditService}
                        checked={performedBy === 'professional'}
                        onClick={() => setPerformedBy('professional')}
                      />
                    }
                    label={t('APP.SERVICE.SELECT_FIELD.LABEL.PERFORMED_BY.PROFESSIONNAL')}
                    labelPlacement="start"
                  />
                </FormGroup>
              </FormControl>

              {/* label */}
              <FormControl margin="normal" component="fieldset" fullWidth>
                <TextField
                  id="label"
                  name="label"
                  value={label}
                  type="text"
                  onChange={event => setLabel(event.target.value)}
                  label={t('APP.SERVICE.TEXT_FIELD.LABEL.TITLE')}
                  variant="outlined"
                  fullWidth
                  multiline
                  InputLabelProps={{
                    shrink: true,
                  }}
                  inputProps={{
                    maxLength: 45,
                  }}
                  required
                  disabled={!expertise}
                />
              </FormControl>

              {/* description */}
              <FormControl
                margin="normal"
                component="fieldset"
                fullWidth
                style={{ color: !canEditService ? '#00000061' : '#000000de' }}
              >
                <InputLabel
                  required
                  variant="outlined"
                  shrink
                  className={classes.selectLabel}
                  disabled={!canEditService || !expertise}
                >
                  {t('APP.SERVICE.TEXT_FIELD.LABEL.DESCRIPTION')}
                </InputLabel>
                <Editor
                  name="description"
                  value={service?.id ? service?.description : expertise?.description || ''}
                  onChange={event => setDescription(event.target.value)}
                  options={['inline', 'emoji', 'list', 'link']}
                  readOnly={!canEditService || !expertise}
                  editorStyle={{ height: '200px' }}
                />
              </FormControl>

              {/* image */}
              <FormControl component="fieldset" fullWidth>
                <Typography className={classes.label}>
                  {t('APP.ARTICLES.TEXT_FIELD.LABEL.COVER')}
                </Typography>
                <FilePond
                  files={images}
                  allowMultiple={false}
                  maxFiles={1}
                  instantUpload={false}
                  acceptedFileTypes={['image/*']}
                  labelIdle={t('APP.USER.IMAGE_FIELD.PLACEHOLDER')}
                  onupdatefiles={fileItems => setImages(fileItems.map(fileItem => fileItem.file))}
                />
              </FormControl>

              {/* public */}
              <FormControl margin="normal" component="div" fullWidth>
                <FormControlLabel
                  component="div"
                  label={t('APP.SERVICE.TEXT_FIELD.LABEL.PUBLIC')}
                  control={
                    <Checkbox
                      name="public"
                      onChange={event => setPublic(event.target.checked)}
                      checked={publicService}
                      color="primary"
                      disabled={!canEditService}
                    />
                  }
                />
              </FormControl>

              {isTransport && renderTransport()}
              {!isTransport && renderPrice()}

              {/* notifiable_roles */}
              <FormControl margin="normal" variant="outlined" component="fieldset" fullWidth>
                <InputLabel
                  variant="outlined"
                  shrink
                  className={classes.selectLabel}
                  htmlFor="select-roles"
                  disabled={!canEditService}
                >
                  {t('APP.SERVICE.SELECT_FIELD.LABEL.NOTIFICATIONS')}
                </InputLabel>
                <Select
                  multiple
                  value={notifiableRoles || ['admin']}
                  onChange={event => setNotifiableRoles(event.target.value)}
                  input={<OutlinedInput labelWidth={0} name="role" id="select-roles" />}
                  renderValue={selected =>
                    selected.map(role => getFormattedRole(role, t)).join(', ')
                  }
                  MenuProps={MenuProps}
                  fullWidth
                  variant="outlined"
                  disabled={!canEditService}
                >
                  {roles.map(role => (
                    <MenuItem
                      key={role?.value}
                      value={role?.value}
                      disabled={role?.value === 'volunteer' && performedBy === 'professional'}
                    >
                      <Checkbox
                        checked={notifiableRoles && notifiableRoles.indexOf(role?.value) !== -1}
                        color="primary"
                      />
                      <ListItemText primary={role?.label} />
                    </MenuItem>
                  ))}
                </Select>
              </FormControl>

              {isTransport && (
                <>
                  <Typography className={classes.hoursLabel}>
                    {t('APP.SERVICE.FIELD.HOURS.LABEL')}
                  </Typography>
                  <Collapse
                    in={CheckRoleRule(
                      getRoleInOrga(currentUser, organisation.holding_slug, organisation.slug),
                      'organisation:configure',
                      organisation,
                    )}
                    className={classes.hours}
                  >
                    <FormHelperText>
                      <InputMultiHours
                        name="openedAt"
                        value={openedAt}
                        onChange={e => setOpenedAt(e.target.value)}
                      />
                    </FormHelperText>
                  </Collapse>
                </>
              )}

              <Grid className={classes.buttonContainer}>
                <Button variant="contained" color="primary" type="submit" disabled={!canSubmit}>
                  {t('SEND')}
                </Button>
              </Grid>
            </form>
          </div>
        </Paper>
      </Grid>
    </>
  );
};

export default ServiceForm;
