import React from 'react';
import { useTranslation } from 'react-i18next';
import { Link } from 'react-router-dom';
import { useSelector } from 'react-redux';
import MenuList from '@material-ui/core/MenuList';
import MenuItem from '@material-ui/core/MenuItem';
import Paper from '@material-ui/core/Paper';
import { makeStyles } from '@material-ui/core/styles';
import Tooltip from '@material-ui/core/Tooltip';
import HomeIcon from '@material-ui/icons/Home';
import LocationCityIcon from '@material-ui/icons/LocationCity';
import InsertDriveFileIcon from '@mui/icons-material/InsertDriveFile';
import SchoolIcon from '@mui/icons-material/School';
import PeopleIcon from '@mui/icons-material/People';
import AssessmentIcon from '@mui/icons-material/Assessment';

import { isLeagueAdmin } from '../../Acl/Controls';
import { grey } from '@material-ui/core/colors';
import SettingsIcon from '@material-ui/icons/Settings';

type Props = {
  league: Object,
  activeItem: String,
};

const useStyles = makeStyles(theme => ({
  paper: {
    width: 48,
    margin: 'auto',
    position: 'sticky',
    top: theme.spacing(9),
    [theme.breakpoints.down('sm')]: {
      width: 'auto',
    },
  },
  list: {
    display: 'grid',
    justifyItems: 'center',
    padding: 0,
    [theme.breakpoints.down('sm')]: {
      display: 'flex',
      flexWrap: 'wrap',
    },
  },
  item: {
    margin: theme.spacing(1),
    padding: theme.spacing(0.5),
    borderRadius: theme.spacing(0.5),
    minHeight: 'auto',
    color: grey[600],
  },
  activeItem: {
    backgroundColor: grey[200],
    margin: theme.spacing(1),
    padding: theme.spacing(0.5),
    borderRadius: theme.spacing(0.5),
    minHeight: 'auto',
  },
}));

const Menu = ({ league, activeItem }: Props) => {
  const classes = useStyles();
  const { t } = useTranslation();
  const currentUser = useSelector(state => state.auth.currentUser);

  const items = [
    {
      id: 'helpers',
      icon: <HomeIcon />,
      title: t('APP.LEAGUES.MENU.HELPERS'),
      link: `/leagues/${league.id}/helpers`,
    },
    {
      id: 'holdings',
      icon: <LocationCityIcon />,
      title: t('APP.LEAGUES.MENU.HOLDINGS'),
      link: `/leagues/${league.id}/holdings`,
    },
    {
      id: 'articles',
      icon: <InsertDriveFileIcon />,
      title: t('APP.LEAGUES.MENU.ARTICLES'),
      link: `/leagues/${league.id}/articles`,
    },
    {
      id: 'courses',
      icon: <SchoolIcon />,
      title: t('APP.LEAGUES.MENU.COURSES'),
      link: `/leagues/${league.id}/formations`,
    },
    {
      id: 'communities',
      icon: <PeopleIcon />,
      title: t('APP.LEAGUES.MENU.COMMUNITIES'),
      link: `/leagues/${league.id}/communities`,
    },
    {
      id: 'analytics',
      icon: <AssessmentIcon />,
      title: t('APP.LEAGUES.MENU.ANALYTICS'),
      link: `/leagues/${league.id}/analytics`,
    },
    {
      id: 'settings',
      icon: <SettingsIcon />,
      title: t('APP.LEAGUES.MENU.SETTINGS'),
      link: `/leagues/${league.id}/settings`,
    },
  ];

  return (
    <Paper className={classes.paper}>
      {isLeagueAdmin(league.slug, currentUser) && (
        <MenuList className={classes.list}>
          {items.map(item => (
            <MenuItem
              component={Link}
              to={item.link}
              className={item.id === activeItem ? classes.activeItem : classes.item}
              key={item.id}
            >
              <Tooltip title={item.title}>{item.icon}</Tooltip>
            </MenuItem>
          ))}
        </MenuList>
      )}
    </Paper>
  );
};

export default Menu;
